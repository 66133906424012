import React, {useEffect, useState} from "react";
import {Dialog, Slide} from "@mui/material";
import QuestionDialogContent from "./QuestionDialogContent";
import texture from '../assets/images/evento/SGO/texture.png'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Question({idPlaying,url, user, event, theme, nomeInviato}){

    const [open, setOpen] = useState(false)

    useEffect(()=> {
        const handleNewId = async ()=> {
            handleClose()
            setTimeout(() => {
                idPlaying === '' ? handleClose() : handleClickOpen()
            },300)
        }
        handleNewId().catch(console.error)
    },[idPlaying])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <Dialog fullScreen open={open && nomeInviato!=='' && nomeInviato} hideBackdrop={true} disableEscapeKeyDown={true} TransitionComponent={Transition} onClose={handleClose}>
            {open &&<QuestionDialogContent idPlaying={idPlaying} url={url} user={user} theme={theme}/>}
        </Dialog>
)
}
