import React, {useEffect, useState} from "react";
import Header from "./Header";
import Buttons from "./Buttons";
import Question from "./Question";
import {useParams} from "react-router-dom";
import {firestore} from "../firebase/clientApp";
import ApplyForm from "./ApplyForm";
import {Box, Stack, Typography} from "@mui/material";
import LogoBox from "./LogoBox";
import Agenda from "./Agenda";
import {useDocument} from "react-firebase-hooks/firestore";
import {NomeCognome} from "./NomeCognome";
import {useLocalStorage} from "../functions/customHooks/useLocalStorage";

export default function VoteContent({user, event}){
    const {id} = useParams()
    const [idPlaying, setIdPlaying] = useState('')
    const [eventPlaying, setEventPlaying] = useState('')
    const [nomeInviato, setNomeInviato] = useLocalStorage('nomeInviato', '')
    const url = 'users/plesh/events/'
    const [theme, themeLoading, themeError] = useDocument(firestore.collection('users/plesh/events/' + id + '/themes').doc(event?.data().theme))

    useEffect(() => {
        getIdPlaying()
    }, [])

    function getIdPlaying() {
        firestore.collection(url).doc(id).onSnapshot(s => {
            let e= s.data().elementPlaying
            let g = s.data().playing
            setIdPlaying(e)
            setEventPlaying(g)
        })
    }

    const handleNomeInviato = (nome) => {
        setNomeInviato(nome)
    }

    return(
        <Box>
            <Stack direction={"column"} justifyContent={"center"} alignContent={"center"} style={{height:'75vh'}} sx={{py:2}} spacing={4}>
                {event.data().logo && <LogoBox logo={event.data().logoUrl ? event.data().logoUrl : 'https://storage.googleapis.com/2ndstage-public/logos/logo-2nd.png'}/>}
                <NomeCognome handleNomeInviato={handleNomeInviato} nomeInviato={nomeInviato}/>
            </Stack>
                {(event.data().applausometro && nomeInviato!=='' && nomeInviato) && <Buttons url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying} user={user}/>}
                {(theme && !themeLoading && !themeError) && <Question nomeInviato={nomeInviato} event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>}
        </Box>

    )
}
