import React, {useEffect, useState} from "react";
import {firebase, firestore} from "../firebase/clientApp";
import Auth from "./auth";
import VoteContent from "../components/VoteContent";
import {useParams} from "react-router-dom";
import {Box, Container} from "@mui/material";
import {useDocument} from "react-firebase-hooks/firestore";

export default function Vote() {
    const {id} = useParams()
    const [user, setUser] = useState(null);
    const [event, eventLoading, eventError] = useDocument(firestore.doc('users/plesh/events/' + id))

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            setUser(user);
        })
    }, [])

    return (
        <Box>
            {(event && !eventLoading && !eventError) &&
                <Container maxWidth={'sm'} className={'vote'} style={{maxHeight: '100%'}}>
                    {!user ? <Auth event={event}/>
                        :
                        <VoteContent user={user} event={event}/>
                    }
                </Container>
            }
        </Box>
    )
}
