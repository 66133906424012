import React from "react";
import {Box, Typography} from "@mui/material";
import {QnaQuestion} from "./Questions/QnaQuestion";
import axios from "axios";
import {firebase} from "../firebase/clientApp";
import {useLocalStorage} from "../functions/customHooks/useLocalStorage";


export const NomeCognome = ({nomeInviato, handleNomeInviato}) => {
    function sendAnswer(answer) {
        let user = firebase.auth().currentUser
        let data = {
            answer: answer,
            user:user.uid
        }
        axios.post('https://api.secondstage.app/answer/sOHZeGuy3ogE0k0iuKdt/voOdVxoEW4FksAsAMTzO', data)
            .then(response => {
                handleNomeInviato(answer)
            })
            .catch( error => {
            })
    }

    return (
        <Box>
            {(nomeInviato==='' || !nomeInviato) ? <Typography variant={"h6"} fontWeight={"bold"} textAlign={'center'} sx={{pb: 2}}>Inserisci il tuo nome e
                cognome per interagire</Typography>
                :
                <Box>
                    <Typography fontWeight={"bold"} textAlign={'center'} sx={{}}>Nome e cognome:</Typography>
                    <Typography variant={"h6"} fontWeight={"bold"} textAlign={'center'} sx={{pb: 2}}>{nomeInviato}</Typography>
                </Box>
            }
            {(nomeInviato==='' || !nomeInviato) && <QnaQuestion success={nomeInviato !== ''} sendAnswer={sendAnswer}/>}
        </Box>
    )
}
