import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {auth, firebase} from "../firebase/clientApp";
import 'firebase/compat/auth';
import logo from '../assets/images/logo-2nd.png'
import {Button, Stack, useTheme} from "@mui/material";

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'redirect',
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        {
            provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            scopes: ['public_profile', 'email']
        }
    ],
};

const signin = () => {
    auth.signInAnonymously().then(() => console.log('qui')).catch(alert);
}

function SignInScreen({event}) {
    return (
        <div>
            <img style={{width: '70%', maxWidth: '400px', marginTop: '2rem'}} src={logo}/>
            <p>Esegui il login:</p>
            {event.data().autenticazione ?
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
                :
                <Button style={{
                    height: '4rem',
                    width: '16rem',
                    fontSize: '1.3rem',
                    background: 'linear-gradient(90deg, rgba(231,110,81,1) 0%, rgba(234,77,149,1) 100%)',
                    border: 'none',
                    color: '#000000',
                    fontWeight: 'bold',
                    borderRadius: '2rem',
                    borderWidth: '3px'
                }}
                        onClick={signin}>Entra</Button>}
            <Stack direction={"row"} justifyContent={'space-evenly'} sx={{my: {xs: 10, md: 0}, bottom: 0}}
                   alignItems={"center"}>
                <a href={'https://2ndstage.app/privacy-policy'} target={'_blank'} style={{
                    color: '#fff',
                    textDecoration: 'none',
                    opacity: 0.4,
                    fontFamily: 'Forever Grotesk',
                    fontWeight: 400,
                    fontSize: '1.313rem'
                }}>Cookies</a>
                <a href={'https://2ndstage.app/privacy-policy'} target={'_blank'} style={{
                    color: '#fff',
                    textDecoration: 'none',
                    opacity: 0.4,
                    fontFamily: 'Forever Grotesk',
                    fontWeight: 400,
                    fontSize: '1.313rem'
                }}>Privacy</a>
            </Stack>
        </div>
    );
}

export default SignInScreen;
