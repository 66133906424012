import React, {useEffect, useState} from "react";
import {AppBar, Avatar, Box, Container, IconButton, Toolbar, Typography} from "@mui/material";

function Header ({user}) {
    const [photo, setPhoto] = useState('')

    useEffect(()=> {
        setPhoto(user!=={} ? user.photoURL : '')
    },[user])

    return (
        <AppBar position="static">
            <Container maxWidth={"xl"}>
                <Toolbar disableGutters>
                    <Typography variant={'h4'}>
                        Evento
                    </Typography>
                    <Box sx={{flexGrow: 1}}/>
                    <Box sx={{flexGrow: 0}}>
                        {user && <IconButton sx={{p: 0}}>
                            <Avatar alt={user.displayName} src={photo}/>
                        </IconButton>}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Header