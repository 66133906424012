import './App.css';
import {Navigate, Route, Routes, useParams} from "react-router-dom";
import pleshDark from "./theme/pleshDark";
import Home from "./pages/Home";
import Vote from "./pages/Vote";
import React from "react";
import {CssBaseline, ThemeProvider} from "@mui/material";

function App() {
    return (
        <ThemeProvider theme={pleshDark}>
            <CssBaseline/>
            <div className="App">
                <Routes>
                    <Route path='/' element={<Navigate to={'/vote/sOHZeGuy3ogE0k0iuKdt'}/>}/>
                    <Route path='/vote/:id' element={<Vote/>}/>
                </Routes>
            </div>
        </ThemeProvider>
    );
}

export default App;
