import React from "react";
import {AppBar, Box, Stack, Toolbar, Typography, useTheme} from "@mui/material";
import {Textfit} from 'react-textfit';

export default function QuestionBar({tipo, domanda, colore}) {
    const theme = useTheme()
    return (
        <Box sx={{position: 'relative', height: '30%', width: '100%', padding:'5rem 1rem'}}>
            <Textfit mode={'multi'} min={10} max={30}
                     style={{
                         fontWeight: '400',
                         fontFamily: 'Proto Grotesk',
                         width: '100%',
                         height: '100%',
                         textAlign: 'center',
                         display: 'flex',
                         alignItems: 'center',
                         justifyContent: 'center'
                     }}>
                {domanda}
            </Textfit>
        </Box>
    )
}
